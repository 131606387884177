import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import CalendarView from "../../../../global_component/Calendar/CalendarView";
import FanMeetingLayout from "./fanMeeting/FanMeetingLayout";

const FanMeetingTab = () => {
  // const [date, setDate] = useState(new Date());
  const [data, setData] = useState({})
  const { id } = useParams()
  useEffect(() => {
    axios.get(`/admin/meetings/${id}`).then(res => {
      setData(res.data.data)
    })
  }, [])
  return (
    <div className="d-flex px-3 py-3">
      {/* <div>
        <h6 className='text-white mb-5 mt-3 ' > Specific date/ Data Range </h6>
        <CalendarView date={date} setDate={setDate} />
      </div> */}
      <div className='flex-fill' >
        <FanMeetingLayout data={data} />
      </div>
    </div>
  );
};

export default FanMeetingTab;
