import { notification, Button } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import EditMiscModal from "./EditTitleModal";
import MiscModal from "./TitleModal";
import {
  FiTrash,
  FiEdit
} from "react-icons/fi";
import PreLoader from "../../../global_component/PreLoader";

const Title = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState({ title: "", id: "" });

  const getTitle = () => {
    setLoading(true);
    axios.get("/admin/title/").then((res) => {
      setData(res.data.data.result);
      setLoading(false);
    });
  };

  useEffect(() => {
    getTitle();
  }, []);

  const deleteTitle = (id) => {
    axios
      .delete(`/admin/title/${id}`)
      .then((res) => {
        getTitle();
        notification.success({ message: 'Title Deleted' })
      })
      .catch((err) => console.log(err));
  };

  const editTitle = (id, title) => {
    axios.put(`/admin/title/`, { ID: id, UpdatedTitle: title }).then(res => {
      setOpenEdit(false)
      notification.success({ message: 'Title Edit Successfully' })
      getTitle()
    }).catch(err => {
      console.log(err)
    })
  };

  return (
    <div>

      <div className="text-white div-dynamic mt-2 px-3">
        <div className="d-flex align-items-center my-3">
          <h5 className='d-inline-block text-white' >
            Titles
          </h5>
          <Button
            type="text"
						className="btn--secondary ml-auto"
            onClick={() => setOpen(true)}
          >
            Add Title
          </Button>
        </div>
        <div>
          {loading && <PreLoader />}
          {!loading && <table>
            <thead>
              <tr className="tr-dynamic">
                <th>Sl.No</th>
                <th>Title</th>
                <th className="text-right">Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 && data.map((data, i) => (
                <tr className="tr-dynamic">
                  <td>{i + 1}</td>
                  <td>{data.category}</td>{" "}
                  <td
                    className='td'
                  >
                    <div className='d-flex align-items-center justify-content-end'>
                      <button
                        className='btn btn-outline-info btn-sm d-flex align-items-center'
                        onClick={() => {
                          setValue({ title: data.category, id: data.id });
                          setOpenEdit(true)
                        }}
                      >
                        <FiEdit className='mr-2' /> Edit
                      </button>
                      <button
                        className='btn btn-outline-danger btn-sm d-flex align-items-center ml-4'
                        onClick={() => {
                          deleteTitle(data.id);
                        }}
                      >
                        <FiTrash className='mr-2' /> Delete
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {(!data || data.length === 0) &&
                <tr>
                  <td style={{ textAlign: 'center' }} colSpan={3}>
                    No data found!
                  </td>
                </tr>
              }
            </tbody>
          </table>}
        </div>
      </div>


      <MiscModal open={open} setOpen={setOpen} getTitle={getTitle} />
      <EditMiscModal editTitle={editTitle} open={openEdit} setOpen={setOpenEdit} value={value} setValue={setValue} />
    </div>
  );
};

export default Title;
