import React from "react";
import logo from "../../assets/fanclub logo.png";

const Logo = ({ size = "178px"}) => {
  return (
    <div>
      <img
        src={logo}
        alt="fanclub logo"
        style={{ width: size, margin: "auto", display: "block", padding: '25px 0px' }}
      />
    </div>
  );
};

export default Logo;
