import React, { useState } from "react";
import { Modal, Form, Input, Space, Radio } from "antd";

const RejectRequestModal = ({ open, setOpen, setValue, value,Reject}) => {
  const [input, setInput] = useState("");
  return (
    <Modal
      centered={true}
      title="Basic Modal"
      bodyStyle={{
        background: "#222222",
        borderRadius: "15px",
        padding: "20px 20px 10px 20px",
      }}
      maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
      footer={false}
      visible={open}
      onCancel={() => setOpen(false)}
    >
      <h5 className="text-white"> Reason for disapproval  </h5>
      <Form>
        <Radio.Group
          onChange={(e) => setValue(e.target.value)}
          
          style={{width:'100%'}}
        >
          <Space direction="vertical">
            {" "}
            <Radio style={{ color: "white" }} value={"Govt. ID picture is not clear."}>
              Govt. ID picture is not clear.
            </Radio>
            <Radio
              style={{ color: "white" }}
              value={"Social links are broken."}
            >
              Social links are broken.
            </Radio>
            <Radio style={{ width:'480px'}}  value={input}>
              {" "}
              <Input
                style={{
                  background: "#222222",
                  border: "none",
                  margin: "10px 0",
                  borderBottom: "1px solid #3D3D3D",
                  color: "white",
                  width:'450px'
                }}
                required={true}
                placeholder="Enter Reason"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
            </Radio>{" "}
          </Space>
        </Radio.Group>
        <div className="d-flex justify-content-end mt-2 ">
          <Space>
            <h6
              style={{ color: "#F4E900", cursor: "pointer" }}
              onClick={() => {
               Reject()
              }}
            >
             Decline
            </h6>
            <h6
              style={{ color: "white", cursor: "pointer" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </h6>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default RejectRequestModal;
