import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PreLoader from "../../global_component/PreLoader";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const history = useHistory()

  const getRequest = () => {
    try {
      setLoading(true);
      axios
        .get(`/admin/report-request`)
        .then((res) => {
          setReports(res.data.data);
        });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequest();
  }, []);

  return loading ? <PreLoader /> : (<>
    <div>
      <div className="mt-3 text-white px-3 py-3 div-dynamic ">
        <table>
          <thead>
            <tr>
              <th>Reported On</th>
              <th>Reported By</th>
              <th>Reason</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {!loading && reports.length > 0 && reports.map((data, i) => (
              <tr className="tr-dynamic" key={`row-${i}`}>
                <td>{data.reported_on}</td>
                <td>{data.reported_by}</td>
                <td>{data.description}</td>
                <td className='td' onClick={() => {
                  history.push({
                    pathname: `/creators/${data.reported_on_user_id}`,
                    state: { active: 7 }
                  })
                }} >View</td>
              </tr>
            ))}
            {!loading && reports.length <= 0 && <tr><td className="text-center" colSpan="4">No Data Found</td></tr>}
          </tbody>
        </table>
      </div>
    </div>
  </>);
};

export default Reports;
