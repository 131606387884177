import React from "react";
import { FiSearch } from "react-icons/fi";
import './globalstyle.css'
const SearchBar = ({ input, setInput, placeholder = "Search users",func}) => {

     const submitHandle = (e) => {
        if(e.key === 'Enter'){
           func()
        }
     }


  return (
    <div className="text-white mt-4 ">
      <span
        style={{
          background: "#222222",
          borderRadius: "28px",
          padding: "12px",
          height: "44px",
        }}
      >
        <FiSearch size="1rem" />
        <input
          type="text"
          value={input}
          box='search'
          placeholder={placeholder}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => submitHandle(e)}
        />
      </span> 
      <span>
        
      </span>
    </div>
  );
};

export default SearchBar;
