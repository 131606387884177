import React, { useState } from 'react'
import './requests.css'
import { useLocation } from 'react-router-dom'

import VerifyInfluencer from './VerifyInfluencer/VerifyInfluencer'
import ReportRequest from './ReportRequest/ReportRequest'
import InviteCodeRequest from './InviteCodeRequest'


const Request = () => {

  const location = useLocation()
  const [active, setActive] = useState(location.state ? location.state.active : 1)

  return (
    <>
      <div className='detail-layout px-3 py-3 mt-3 '  >
        <div className="d-flex flex-row  ">
          {['Invite Codes', 'User Application', 'Report Request'].map((data, i) => {
            return <span key={i} onClick={() => setActive(i + 1)} className={`flex-fill ${active === i + 1 ? 'fantab-active' : 'fantab'}`} >
              <h6 className='text-center' > {data} </h6>
            </span>
          })}
        </div>
        <div>
          {active === 1 ? <InviteCodeRequest /> : null}
          {active === 2 ? <VerifyInfluencer /> : null}
          {active === 3 ? <ReportRequest /> : null}
        </div>
      </div> </>
  )
}

export default Request
