import React,{useState} from "react";
import { useHistory } from 'react-router'
import axios from "axios";
import { notification } from "antd";

import TitleDetail from "../../../../global_component/TitleDetail";
import SocialIcons from "../../../../global_component/SocialIcons";
import ModalPopUp from "../../../../global_component/ModalPopUp";

const InfluencerDetailsTab = ({ user, getInfluencer, slink = [] }) => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [func, setFunc] = useState();
  const [verified, setVerified] = useState(user && user.verified ? true : false)
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(user && user.is_hidden ? 'Unhide' : "Hide");
  const adminData = JSON.parse(localStorage.getItem("admin"));

  const updateStatus = (block = true) => {
    axios
      .put(`/admin/${!block ? 'activate' : 'deactivate'}/`, { user_id: user.id })
      .then((res) => {
        notification.success({ message: `Account ${!block ? 'activated' : 'blocked'} successfully!` });
        getInfluencer();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUser = () => {
    if (window.confirm('Are you sure you want to soft delete this user?')) {
      axios
      .delete(`/admin/user/${user.id}`)
      .then(() => {
        notification.success({ message: `User deleted successfully!` });
        history.push("/users");
        
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const Verify = () => {
    axios
      .post("/admin/verify-user", {
        admin_id: adminData.id,
        user_id: user.id,
        status: "approved",
        reason: "Verification Completed",
      })
      .then((res) => {
        notification.success({ message: "Verification Completed" });
        setModal(false);
        // history.push("/users");
        setVerified(true)
        getInfluencer();
      });
  };

  const Hide = () => {
    axios
      .patch(`/admin/hide/${user.id}`)
      .then((res) => {
        setOpenModal(false);
        notification.success({ message: "User Hide Successfully." });
        getInfluencer();
        setActive("Unhide")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Unhide = () => {
    axios
      .patch(`/admin/unhide/${user.id}`)
      .then((res) => {
        setOpenModal(false);
        setActive("Hide");
        getInfluencer();
        
        notification.success({ message: "User Unhide Successfully." });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {open && (<ModalPopUp
        open={open}
        cancel={setOpen}
        func={Verify}
        title='Do you want to Verify'
      />)}
      {modal && ( <ModalPopUp
        open={modal}
        cancel={setModal}
        func={Hide}
        title='Do you want to Hide ?'
      />)}
      {openModal && (<ModalPopUp
        open={openModal}
        cancel={setOpenModal}
        func={Unhide}
        title='Do you want to Unhide ?'
      />)}
      <section className="d-flex mt-4">
        <div className="flex-fill px-3 ">
          <TitleDetail
            title="Name"
            detail={user.fullname ? user.fullname : "N/A"}
          />
          <TitleDetail title="Email" detail={user.email ? user.email : "N/A"} />
          <TitleDetail
            title="Country"
            detail={user.country ? user.country : "N/A"}
          />
          <TitleDetail
            title="Wallet Address"
            detail={!!user?.wallet_address ? user?.wallet_address : "N/A"}
          />
          {/* <h6 style={{ color: "rgb(150, 150, 150)" }}>Social Media Links</h6> */}
          <div className="d-flex flex-wrap">
            {slink.map((data, i) => {
              return (
                <SocialIcons
                  key={i}
                  platform={data.platform}
                  link={data.link}
                />
              );
            })}
          </div>
        </div>
        <div className="flex-fill px-3 ">
          <TitleDetail
            title="Registered Number"
            detail={user.mobile ? user.mobile : "N/A"}
          />
          <TitleDetail
            title="Date of Birth"
            detail={user.dob ? user.dob : "N/A"}
          />
          <TitleDetail title="Title" detail={user.title ? user.title : "N/A"} />
        </div>
        <div className="flex-fill px-3 text-center ">
          <img
            src={`${process.env.REACT_APP_API_IMAGE}${user.profilePic}`}
            alt="profile"
            className="rounded-circle mb-4 "
            style={{ width: "100px", height: "100px" }}
          />
          
          <TitleDetail
            title="Bio"
            detail={user.bio ? user.bio : "N/A"}
          />
        </div>
      </section>

      

      <div>
          <div
            className="text-white my-3 pb-2"
            style={{
              color: "#F4E912",
              borderBottom: "1px solid #F4E912",
              fontSize: "18px",
            }}
          >
            Verification
          </div>
        </div>
        <section className="d-flex mt-4">
          <div className=" flex-fill px-3 ">
            <TitleDetail
              title="Display Name"
              detail={user.display_name ? user.display_name : "N/A"}
            />
            <TitleDetail
              title="Platform user popular on"
              detail={
                user.platform_user_popular_on
                  ? user.platform_user_popular_on
                  : "N/A"
              }
            />
            <TitleDetail
              title="Social Media Platform To Verify"
              detail={
                user.social_media_platform_to_verify
                  ? user.social_media_platform_to_verify
                  : "N/A"
              }
            />
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            <TitleDetail
              title="Followers count platform user popular on"
              detail={
                user.followers_count_platform_user_popular_on
                  ? user.followers_count_platform_user_popular_on
                  : "N/A"
              }
            />
            {user.social_media_platform_to_verify === "Youtube" ? (
              <TitleDetail
                title="Username"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.title
                    : "N/A"
                }
              />
            ) : (
              <TitleDetail
                title="Username"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.username
                    : "N/A"
                }
              />
            )}
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            <TitleDetail
              title="Handle platform user popular on"
              detail={
                user.handle_platform_user_popular_on
                  ? user.handle_platform_user_popular_on
                  : "N/A"
              }
            />
            {user.social_media_platform_to_verify === "Instagram" && (
              <TitleDetail
                title="Account Type"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.account_type
                    : "N/A"
                }
              />
            )}
            {user.social_media_platform_to_verify === "Twitter" && (
              <TitleDetail
                title="Followers Count"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.followers_count
                    : "N/A"
                }
              />
            )}
            {user.social_media_platform_to_verify === "Youtube" && (
              <TitleDetail
                title="Subscriber Count"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.subscriberCount
                    : "N/A"
                }
              />
            )}
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            {user.social_media_platform_to_verify !== null && (
              <TitleDetail
                title="Others Work"
                detail={user.other_works ? user.other_works : "N/A"}
              />
            )}
            {user.social_media_platform_to_verify === "Twitter" && (
              <TitleDetail
                title="Verified"
                detail={user.data_for_verification.verified ? "Yes" : "No"}
              />
            )}
            {user.social_media_platform_to_verify === "Youtube" && (
              <TitleDetail
                title="Custom URL"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.customUrl
                    : "N/A"
                }
              />
            )}
          </div>
        </section>      



      <div className="d-flex justify-content-end pt-4 pb-2 px-4">
       {!verified && (<button className="btn--secondary btn--solid px-4 py-2 mx-4" onClick={() => setOpen(true)}>
          Verify
        </button>)}
        {verified && (<button className="btn--secondary btn--solid px-4 py-2 mx-4" disabled >
          Verified
        </button>)}

        {user.active && (<button className="btn--secondary btn--solid px-4 py-2" onClick={() => updateStatus(user.active)}>
          {user.active ? 'Delete User' : 'Deleted'}
        </button>)}
        {!user.active && (<button className="btn--verified btn--solid px-4 py-2" onClick={() => updateStatus(user.active)}>
          {user.active ? 'Delete User' : 'Deleted'}
        </button>)} 

        <button className="btn--danger btn--solid px-4 py-2 mx-4" onClick={deleteUser}>
         Soft Delete
        </button>

        {active === "Hide" && (<button className="btn--secondary btn--solid px-4 py-2" onClick={() => setModal(true)}>
          {active}
        </button>)}
        {active === "Unhide" &&(<button className="btn--verified btn--solid px-4 py-2" onClick={() => setOpenModal(true)}>
          {active}
        </button>)}
      </div>
      
      {/* <HideRequestModal
        open={openModal}
        setOpen={setOpenModal}
        function={active}
        active={active}
      /> */}
    </>
  );
};

export default InfluencerDetailsTab;
