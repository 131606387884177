import React, { useState } from "react";
import { Modal, Form, Space, InputNumber } from "antd";

const InitiateSettlementModal = ({ open, setOpen, Initiate , amt }) => {
  const [amount, setAmount] = useState(amt ? amt : 0);

  return (
    <Modal
      centered={true}
      title="Basic Modal"
      bodyStyle={{
        background: "#222222",
        borderRadius: "15px",
        padding: "20px 20px 10px 20px",
      }}
      maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
      footer={false}
      visible={open}
      onCancel={() => setOpen(false)}
    >
      <h5 className="text-white" style={{ marginLeft: 140 + "px" }}>
        Settlement Amount{" "}
      </h5>
      <Form style={{ marginTop: 30 + "px" }}>
        <Space className="d-flex justify-content-center my-5">
          <InputNumber
            defaultValue={amount}
            style={{background: 'rgb(34, 34, 34)',
              borderTop: 'none',
              borderRight: 'none',
              borderBottom: '1px solid rgb(61, 61, 61)',
              borderLeft: 'none',
              borderImage: 'initial',
              margin: '10px 0px',
              color: 'white'}}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
            max={amount}
            min={0}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(e) => setAmount(e)}
          />
        </Space>
        <div className="d-flex justify-content-center mt-2 mb-3 ">
          <Space>
            <button
              className="btn--secondary btn--solid px-4 py-2 mx-4"
              onClick={() => Initiate(amount)}
            >
              Save
            </button>
            <button
              className="btn--secondary btn--solid px-4 py-2 mx-4"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default InitiateSettlementModal;
