import React, { Suspense, useContext } from "react";
import {Route, Switch } from "react-router";
import Layout from "./components/global_component/Layout";
import InfluencerHome from "./components/modules/influencer_profile/InfluencerHome";
import FanHome from "./components/modules/fan_page/FanHome";
import Admin from "./components/modules/admin/Admin";
import { StateContext } from "./components/statemangement/ContextApi";
import FanDetails from "./components/modules/fan_page/component/FanDetails";
import InfluencerDetails from "./components/modules/influencer_profile/component/InfluencerDetails";
import { Spin } from "antd";
import Miscellaneous from "./components/modules/miscellaneous/Miscellaneous";
import Request from "./components/modules/requests/Request";
import ViewApplication from "./components/modules/requests/VerifyInfluencer/ViewApplication";
import EarlyAccess from "./components/modules/early_acess/EarlyAccess";
import InviteCodes from "./components/modules/invite_codes";
import Reports from "./components/modules/reports";
import Commission from "./components/modules/commission/Commission";
import Wallet from "./components/modules/Wallet/Wallet";

const LoginPage = React.lazy(() =>
  import("./components/modules/login/LoginPage")
);

const Router = () => {
  const { success } = useContext(StateContext);
  const auth = success;
  return (
    <>
      {auth === 'true' ? (
        <Layout >
          <Switch>
            <Route exact path="/admins" component={Admin} />
            <Route exact path="/miscellaneous" component={Miscellaneous} />
            <Route exact path="/users" component={InfluencerHome} />
            <Route exact path="/users/:id"component={InfluencerDetails} />
            <Route exact path="/requests" component={Request} />
            <Route exact path="/requests/application/:id" component={ViewApplication} />
            <Route exact path="/fans" component={FanHome} />
            <Route exact path="/fans/:id" component={FanDetails} />
            <Route exact path="/early-access" component={EarlyAccess} />
            <Route exact path="/invite-codes" component={InviteCodes} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/" component={InfluencerHome} />
            <Route exact path="/wallet" component={Wallet} />
            <Route exact path="/commission" component={Commission} />
          </Switch>
        </Layout>
      ) : (
        <span>
          <Suspense fallback={<Spin size="large" />}>
            <Switch>
              <Route exact path="/">               
                <LoginPage />
              </Route>
            </Switch>
          </Suspense>
        </span>
      )}
    </>
  );
};

export default Router;
