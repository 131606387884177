import React, { useState, useEffect, useCallback } from "react";
import { Button, notification } from "antd";
import InitiateSettlementModal from "./InitiateSettlementModal";
import axios from "axios";
import TitleDetail from "../../../../global_component/TitleDetail";
import PreLoader from "../../../../global_component/PreLoader";
import WalletView from "../../../../global_component/Wallet/WalletView";

const InfluencerWallet = ({ id }) => {
  const [openModal, setOpenModal] = useState(false);
  const [walletData, setWalletData] = useState(null);
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getWalletData = useCallback((value) => {
    setLoading(true);
    axios
      .get(`admin/wallet/${value}`)
      .then((res) => {
        setWalletData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getWalletData(id);
    }
  }, [getWalletData, id]);

  const getTransactionData = useCallback((value) => {
    setLoading(true);
    axios
      .get(`admin/transactions?user_id=${value}`)
      .then((res) => {
        setTransactionData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (id) {
      getTransactionData(id);
    }
  }, [getTransactionData, id]);

  const Initiate = (amt) => {
    if (amt !== 0) {
      if (amt <= walletData.wallet.balance) {
        axios
          .post(`/admin/stripe/transfer-payout/manual/`, {
            user_id: id,
            amount: amt,
          })
          .then((res) => {
            if (res.data.success) {
              setOpenModal(false);
              notification.success({ message: res.data.message });
              getWalletData(id);
            }
          })
          .catch((err) => {
            notification.error({ message: err.data.message });
          });
      } else {
        notification.error({
          message: "Please enter less amount then current balance.",
        });
      }
    } else {
      notification.error({ message: "No amount to settle." });
    }
  };

  //checking balance of initial settlement for pop-up
  const intialSettlement = () => {
    if (walletData && walletData.wallet !== null) {
      if (walletData.wallet != null || walletData.wallet.balance === 0) {
        notification.error({ message: "No amount to settle." });
      } else {
        setOpenModal(true);
      }
    } else {
      notification.error({ message: "No amount to settle." });
    } 
  }
  
  return loading ? (
    <PreLoader />
  ) : (
    <>
      {openModal && (
        <InitiateSettlementModal
          open={openModal}
          setOpen={setOpenModal}
          Initiate={Initiate}
          amt={
            walletData && walletData.wallet != null
              ? walletData.wallet.balance
              : 0
          }
        />
      )}
      <div className="d-flex flex-column px-3 pt-4 pb-4">
        <div className="d-flex align-items-center">
          <h5 className="mr-3 mb-0 text-muted">Current Wallet Balance: </h5>
          <h3 className="text-light mb-0">
            {walletData &&
            walletData.wallet != null &&
            walletData.wallet.balance
              ? walletData.wallet.balance+' '
              : 0+' '}
              Coins
          </h3>
          <span className="ml-auto">
            <Button
              type="text"
              className="btn--primary"
              onClick={() => intialSettlement()}
            >
              Initiate Settlement
            </Button>
          </span>
        </div>
        {/* {!walletData.wallet.balance && notification.error({ message: "You don't have enough balance to transfer." })} */}

        <div>
          <div
            className="text-white mt-5 mb-2 pb-2"
            style={{
              color: "#F4E912",
              borderBottom: "1px solid #F4E912",
              fontSize: "18px",
            }}
          >
            Bank details
          </div>
          {walletData && walletData.bank !== null ? (
            <section className="d-flex mt-2">
              <div className="flex-fill px-3 ">
                <TitleDetail
                  title="Account Holder Name"
                  detail={
                    walletData.bank.account_holder_name !== null
                      ? walletData.bank.account_holder_name
                      : "N/A"
                  }
                />
                <TitleDetail
                  title="Account Number"
                  detail={
                    walletData.bank.account_number !== null
                      ? walletData.bank.account_number
                      : "N/A"
                  }
                />
              </div>
              <div className="flex-fill px-3 ">
                <TitleDetail
                  title="Account Holder Type"
                  detail={
                    walletData.bank.account_holder_type !== null
                      ? walletData.bank.account_holder_type
                      : "N/A"
                  }
                />
                <TitleDetail
                  title="Routing Number"
                  detail={
                    walletData.bank.routing_number !== null
                      ? walletData.bank.routing_number
                      : "N/A"
                  }
                />
              </div>
            </section>
          ) : (
            <p className="text-white mt-3">Bank account not linked.</p>
          )}
        </div>

        <div>
          <div
            className="text-white mt-5 mb-2 pb-2"
            style={{
              color: "#F4E912",
              borderBottom: "1px solid #F4E912",
              fontSize: "18px",
            }}
          >
            Transactions
          </div>
          <WalletView userId={id} />
          {/* <div className="text-white px-3 py-3 mt-5 div-admin ">
            {transactionData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Amount</th>
                    <th>Credit/Debit</th>
                    <th>Date</th>
                    <th>Transaction Type</th>
                    <th>To User</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionData.map((data, i) => (
                    <tr key={i} className="tr-admin">
                      <td>{data.id}</td>
                      <td>{data.amount}</td>
                      <td>{data.credit_debit}</td>
                      <td>{moment(data.created_at).format("MMM DD, YYYY")}</td>
                      <td>{data.transaction_type}</td>
                      <td>{data.to_user}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h6 className="text-center text-white"> No Data Found </h6>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InfluencerWallet;
