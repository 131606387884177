import { Spin } from 'antd'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import DetailLayout from '../../../global_component/DetailLayout'
import InfluencerTab from './InfluencerTab'

const InfluencerDetails = () => {
	const { id } = useParams()
	const [user, setUser] = useState({})
	const [loading, setLoading] = useState(false)
	
	const getInfluencer = useCallback(() => {
		setLoading(true)
		axios.get(`/admin/users/${id}`).then(res => {
			setUser(res.data.data)
			setLoading(false)
		})
	},[id])

	useEffect(() => {
		getInfluencer()
	}, [getInfluencer]);

	return (
		<div>
			<DetailLayout type={user && user.account_type ? user.account_type : 'User'} >
				{loading ? <Spin size='large' /> : <InfluencerTab user={user} posts={user.posts} getInfluencer={() => getInfluencer()} />}
			</DetailLayout>
		</div>
	)
}

export default InfluencerDetails
