import React, { useEffect, useState} from "react";
import "./globalstyle.css";
import { FiX } from "react-icons/fi";
import { Space } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
const NoficationPanel = ({ open, setOpen }) => {
  const [notification, setNotification] = useState([]);
  const history = useHistory();

  const getNotifications = () => {
    axios.get("/admin/notification").then((res) => {
      if (res.data.status_code === 200) {
        setNotification(res.data.data);
      }
    });
  };

  const clearAllNotification = () => {
    axios.get("/admin/notification/read-all").then((res) => {
      if (res.data.status_code === 200) {
        getNotifications();
      }
    });
  };

  const clearNotification = (id) => {
    axios.get(`/admin/notification/${id}`).then((res) => {
      if (res.data.status_code === 200) {
        getNotifications();
      }
    });
  };

  useEffect(()=>{
    getNotifications()
  },[])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getNotifications();
  //   }, 60000);

  //   return () => clearInterval(interval);
  // });
  return (
    <>
      {open ? (
        <div>
          <div className="notification-panel">
            <h5 className="text-white ">
              Notification
              <span
                className="float-right scale"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FiX />
              </span>
            </h5>
            <div className="d-block">
             {
               notification.length > 0 ?  <span
               className="text-white cursor-pointer float-right d-block"

               onClick={clearAllNotification}
             >
               Clear Notification
             </span> : null }
             
            </div>
            <div
              style={{
                overFlowY: "auto",
                height: "85vh",
                marginTop: "42px",
              }}
            >
              {notification.length > 0 ? (
                <div className="mt-3">
                  {notification.map((data, i) => {
                     if (data.notification_type === "verification") {
                      return (
                        <div
                          key={i}
                          className="my-1 px-2 py-2 text-white"
                          style={{
                            background: "#3D3D3D",
                            borderRadius: "10px",
                          }}
                        >
                          <h6 className="my-1 text-white"> Verification </h6>
                          <p className="my-1">{data.message}</p>
                          <Space>
                            <span
                              className='cursor-pointer'
                              style={{ borderBottom: "1px solid white" }}
                              onClick={() => clearNotification(data.id)}
                            >
                              Mark Read
                            </span>
                            <span
                              style={{ borderBottom: "1px solid white" }}
                              className="text-white cursor-pointer"
                              onClick={() =>{
                                clearNotification(data.id)
                                history.push(
                                  `/request/application/${data.influencer_id}`
                                )
                                setOpen(false)
                              }}
                            >
                              View Notification
                            </span>
                          </Space>
                        </div>
                      );
                    } 
                    if (data.notification_type === "report") {
                      return (
                        <div
                          key={i}
                          className="my-2 px-2 py-2 text-white"
                          style={{
                            background: "#3D3D3D",
                            borderRadius: "10px",
                          }}
                        >
                          <h6 className="my-1 text-white"> Report </h6>
                          <p className="my-1">{data.message}</p>
                          <Space>
                            <span
                              className='cursor-pointer'
                              style={{ borderBottom: "1px solid white" }}
                              onClick={() => clearNotification(data.id)}
                            >
                              Mark Read
                            </span>
                            <span
                              style={{ borderBottom: "1px solid white" }}
                              className="text-white cursor-pointer"
                              onClick={() =>{
                                clearNotification(data.id)
                                history.push({pathname:'/request',state:{active:2}})
                                setOpen(false)
                              }}
                            >
                              View Notification
                            </span>
                          </Space>
                        </div>
                      );
                    } 
                    return null
                  })}
                </div>
              ) : (
                <h6 className="text-white float-left my-1">
                  No New Notification
                </h6>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NoficationPanel;
