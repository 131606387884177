import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AdminTable from './AdminTable'
import CreateAdmin from './CreateAdmin'
import { Space, Button } from 'antd'

const Admin = () => {
	const [open, setOpen] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false);

	const getAdmin = () => {
		setLoading(true);
		axios.get('/admin/').then(res => {
			setData(res.data.data)
			setLoading(false);
		})
	}

	useEffect(() => {
		getAdmin()
	}, []);
	
	return (
		<>
			<div className='d-flex justify-content-end my-2'>
				<Space>
					{JSON.parse(localStorage.getItem("admin")).is_superuser ? (<Button
							type="text"
							className="btn--secondary"
							onClick={() => setOpen(true)}
						>
							Create Admin
						</Button>
					) : null}
				</Space>
			</div>
			<AdminTable data={data} getAdmin={getAdmin} loading={loading} />
			<CreateAdmin open={open} setOpen={setOpen} getAdmin={getAdmin} />
		</>
	)
}

export default Admin
