import React from "react";
import axios from "axios";
import "./Admin.css";

import PreLoader from "../../global_component/PreLoader";

const AdminTable = ({ data, getAdmin, loading }) => {

  const deleteAdmin = (id) => {
    axios.delete(`/admin/${id}`).then(res => {
      getAdmin()
    })
  }

  return loading ? <PreLoader /> : (<>
    <div className="text-white px-3 py-3 div-admin ">
      <table>
        <thead>
          <tr>
            <th>Sl.No</th>
            <th>Name</th>
            <th>Email</th>
            {JSON.parse(localStorage.getItem("admin")).is_superuser ? (
              <th>Remove Access</th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {data.map((data, i) => (
            <tr className="tr-admin">
              <td>{i + 1}</td>
              <td>{data.fullname}</td> <td>{data.email}</td>
              {JSON.parse(localStorage.getItem("admin")).is_superuser ? (
                <td className="td-ra" style={{ cursor: "pointer" }} onClick={() => {
                  deleteAdmin(data.id)
                }} >
                  Remove Access
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </>);
};

export default AdminTable;
