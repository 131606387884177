import React, { useState, useEffect, useCallback } from "react";
import InfluencerTable from "./InfluencerTable";
import SearchBar from "../../global_component/SearchBar";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Dropdown, Menu, Select } from "antd";
import { FiChevronDown } from "react-icons/fi";

const InfluencerHome = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");
  const [count, setCount] = useState(0);
  const [filterBy, setFilterBy] = useState("name");
  const [filters, setFilters] = useState(null);
  const [filterList, setFilterList] = useState([])

  const getInfluencerList = useCallback((e) => {
    let offset = 0; 
   
    if(e.selected !== undefined){
      const selected = e.selected;
      offset = selected * 10;
    }
    axios
      .get(`/admin/users?limit=${10}&offset=${offset}${filters === '' || filters === null ? '' : `&userType=${filters}`}${ filterBy && input !== '' ? `&filterBy=${filterBy}&filterText=${input}` : ''}`)
      .then((res) => {
        setTableData(res.data.data.result);
        setCount(Math.ceil(res.data.count / 10));
        setLoading(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  },[filters , filterBy , input]);

  const filter = () => {
    axios
      .get("/users/account_types/")
      .then((res) => {
        setFilterList(res.data.data)
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .get("/admin/users?limit=10&offset=0")
      .then((res) => {
        setTableData(res.data.data.result);
        setCount(Math.ceil(res.data.count / 10));
        setLoading(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  }, []);

  useEffect(() => {
      filter()
  },[])

  // const handleSearch = () => {
  //   setLoading(true);
  //   axios
  //     .get(`/admin/users?filterBy=${filterBy}&filterText=${input}`)
  //     .then((res) => {
  //       setTableData(res.data.data.result);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setLoading(false);
  //     });
  // };

  useEffect(() => {
    if(filters !== null){
      getInfluencerList('');
    }
  },[filters , getInfluencerList])

  const arr = [
    { lable: "Name", value: "name" },
    { lable: "Username", value: "username" },
    { lable: "Mobile", value: "mobile" },
    { lable: "Email", value: "email" }
  ];

  const menu = () => (
    <Menu
      style={{
        margin: "0",
        padding: "0",
        float: "right",
        background: "#222222",
        width: "150px",
      }}
    >
      {arr.map((item, i) => (
        <Menu.Item
          style={{
            background: "#222222",
            color: "white",
            paddingRight: "20px",
          }}
          onClick={() => {
            setFilterBy(item.value);
          }}
          key={i + "menu_item"}
        >
          {item.lable}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div className="d-flex ">
        <SearchBar
          input={input}
          setInput={setInput}
          func={() => getInfluencerList('')}
          setTableData={setTableData}
        />
        <Dropdown overlay={menu} trigger={["click"]} className="mt-4 ml-2">
          <h6
            className="d-inline-block text-white  mb-1 text-capitalize"
            style={{ cursor: "pointer" }}
          >
             {filterBy}
            <FiChevronDown />
          </h6>
        </Dropdown>
        <div className="d-flex justify-content-end align-items-start ml-auto mr-4 mt-3">
          <Select className="mr-4" size="default" defaultValue="" onChange={(value) => {setFilters( value === "" ? '' : value);}} style={{ width: 175 }}>
            <Select.Option value="">All Users</Select.Option>
            {filterList.map((data , i) => (
              <Select.Option key={"userType" +i} value={data.id}>{data.account_type}</Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <InfluencerTable
        tableData={tableData}
        setTableData={setTableData}
        loading={loading}
        setLoading={setLoading}
      />
      <div className="float-right">
        {count > 1 ? (
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={getInfluencerList}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
    </>
  );
};

export default InfluencerHome;
