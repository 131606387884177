import React from "react";
import Logo from "../Logo";
import LinkNav from "./LinkNav";
import "./navbar.css";

const SideNav = () => {
  return (
    <div className="sidenav">
      <Logo size="100px" />
      <hr style={{ background: "rgba(215, 214, 214, 0.3)", width: "100px" }} />
      <span className="pt-2 d-block">
        <LinkNav />
      </span>
    </div>
  );
};

export default SideNav;
