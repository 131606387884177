import WalletView from "../../global_component/Wallet/WalletView";

const Wallet = () => {

  return (
    <>
      <WalletView />
    </>
  );
};

export default Wallet;
