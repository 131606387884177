import axios from 'axios'
import { Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import DetailLayout from '../../../global_component/DetailLayout'
import FanTab from './FanTab'

const FanDetails = () => {
	const { id } = useParams();
	const [fan, setFan] = useState({});
	const [loading, setLoading] = useState(false);

	const getFan = useCallback(() => {
		try {
			setLoading(true)
			axios.get(`/admin/fan/${id}`).then(res => {
				setFan(res.data.data);
			}).catch(err => {
				console.log(err)
			})
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false)
		}
	},[id])

	useEffect(() => {
		getFan()
	}, [getFan]);

	return (
		<DetailLayout type='Fan'>
			{loading ? <Spin size='large' /> : <FanTab fan={fan} getFan={getFan} />}
		</DetailLayout>
	)
}

export default FanDetails
