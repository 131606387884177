import React, { useEffect, useState } from "react";
import TitleDetail from "../../../global_component/TitleDetail";
import axios from "axios";
import { notification, Image, Space } from "antd";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import RejectRequestModal from "./RejectRequestModal";

const ViewApplication = () => {
  const [user, setUser] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [value, setValue] = useState("");
  const { id } = useParams();
  const history = useHistory();
  const adminData = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    axios.get(`/admin/users/${id}`).then((res) => {
      setUser(res.data.data);
    });
  }, []);

  const Approve = () => {
    axios
      .post("/admin/verify-user", {
        admin_id: adminData.id,
        user_id: user.id,
        status: "approved",
        reason: "Verification Completed",
      })
      .then((res) => {
        notification.success({ message: "Verification Completed" });
        history.push("/requests");
      });
  };

  const Reject = () => {
    if (value === "" || value === null) {
      notification.error({ message: "Please Select Reason" });
      return false;
    }
    axios
      .post("/admin/verify-user", {
        admin_id: adminData.id,
        user_id: user.id,
        status: "rejected",
        reason: value,
      })
      .then((res) => {
        setValue("");
        setOpenModal(false);
        notification.success({ message: "Application Rejected" });
        history.push("/requests");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className="div-dynamic mt-3 px-3 py-3 vh"
        style={{ position: "relative" }}
      >
        <div>
          <h5
            className="text-white my-3 pb-2"
            style={{ color: "#F4E912", borderBottom: "1px solid #F4E912" }}
          >
            Application Request
          </h5>
        </div>
        <section className="d-flex mt-4">
          <div className="flex-fill px-3 ">
            <TitleDetail
              title="Name"
              detail={user.fullname ? user.fullname : "N/A"}
            />
            <TitleDetail
              title="Email"
              detail={user.email ? user.email : "N/A"}
            />
            <TitleDetail
              title="Country"
              detail={user.country ? user.country : "N/A"}
            />
          </div>
          <div className="flex-fill px-3 ">
            <TitleDetail
              title="Registered Number"
              detail={user.mobile ? user.mobile : "N/A"}
            />
            <TitleDetail
              title="Date of Birth"
              detail={user.dob ? user.dob : "N/A"}
            />
            <TitleDetail
              title="Government ID Proof"
              detail={user.govtID ? null : "N/A"}
            />
            {user.govtID ? (
              <Image
                width={200}
                src={`${process.env.REACT_APP_API_IMAGE}${user.govtID}`}
                alt="govt-Id"
              />
            ) : null}
          </div>
          <div className="flex-fill px-3 text-center ">
            <img
              src={`${process.env.REACT_APP_API_IMAGE}${user.profilePic}`}
              alt="profile"
              className="rounded-circle mb-4 "
              style={{ width: "100px", height: "100px" }}
            />
            {/* <p style={{color:'#8E8E8E'}} >{user.createAt ? user.createAt : 'N/A'}</p>  */}
            <h5 style={{ color: "#8E8E8E" }}>
              User type: <span className="text-white"> Creator </span>
            </h5>
            <TitleDetail
              title="Title"
              detail={user.title ? user.title : "N/A"}
            />
            <TitleDetail
              title="Status/Bio"
              detail={user.status ? user.status : "N/A"}
            />
          </div>
        </section>
        <div>
          <div
            className="text-white my-3 pb-2"
            style={{
              color: "#F4E912",
              borderBottom: "1px solid #F4E912",
              fontSize: "18px",
            }}
          >
            Verification
          </div>
        </div>
        <section className="d-flex mt-4">
          <div className=" flex-fill px-3 ">
            <TitleDetail
              title="Display Name"
              detail={user.display_name ? user.display_name : "N/A"}
            />
            <TitleDetail
              title="Platform user popular on"
              detail={
                user.platform_user_popular_on
                  ? user.platform_user_popular_on
                  : "N/A"
              }
            />
            <TitleDetail
              title="Social Media Platform To Verify"
              detail={
                user.social_media_platform_to_verify
                  ? user.social_media_platform_to_verify
                  : "N/A"
              }
            />
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            <TitleDetail
              title="Followers count platform user popular on"
              detail={
                user.followers_count_platform_user_popular_on
                  ? user.followers_count_platform_user_popular_on
                  : "N/A"
              }
            />
            {user.social_media_platform_to_verify === "Youtube" ? (
              <TitleDetail
                title="Username"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.title
                    : "N/A"
                }
              />
            ) : (
              <TitleDetail
                title="Username"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.username
                    : "N/A"
                }
              />
            )}
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            <TitleDetail
              title="Handle platform user popular on"
              detail={
                user.handle_platform_user_popular_on
                  ? user.handle_platform_user_popular_on
                  : "N/A"
              }
            />
            {user.social_media_platform_to_verify === "Instagram" && (
              <TitleDetail
                title="Account Type"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.account_type
                    : "N/A"
                }
              />
            )}
            {user.social_media_platform_to_verify === "Twitter" && (
              <TitleDetail
                title="Followers Count"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.followers_count
                    : "N/A"
                }
              />
            )}
            {user.social_media_platform_to_verify === "Youtube" && (
              <TitleDetail
                title="Subscriber Count"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.subscriberCount
                    : "N/A"
                }
              />
            )}
          </div>
          <div className="flex-fill px-3 ">
            <div style={{ height: "85px" }}></div>
            {user.social_media_platform_to_verify !== null && (
              <TitleDetail
                title="Others Work"
                detail={user.other_works ? user.other_works : "N/A"}
              />
            )}
            {user.social_media_platform_to_verify === "Twitter" && (
              <TitleDetail
                title="Verified"
                detail={user.data_for_verification.verified ? "Yes" : "No"}
              />
            )}
            {user.social_media_platform_to_verify === "Youtube" && (
              <TitleDetail
                title="Custom URL"
                detail={
                  user.data_for_verification
                    ? user.data_for_verification.customUrl
                    : "N/A"
                }
              />
            )}
          </div>
        </section>

        <div style={{ position: "absolute", bottom: "20px", right: "20px" }}>
          <Space>
            <button onClick={() => Approve()} className="approve-button">
              Approve
            </button>
            <button
              onClick={() => setOpenModal(true)}
              className="reject-button"
            >
              Reject
            </button>
          </Space>
        </div>
      </div>
      <RejectRequestModal
        open={openModal}
        setOpen={setOpenModal}
        Reject={Reject}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default ViewApplication;
