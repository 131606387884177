import { useEffect, useState } from "react";
import axios from "axios";
import CommissionModal from "./CommissionModal";

function Commission() {
    const [commissionData, setCommissionData] = useState([])
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("")

    const getCommissionData = () => {
        axios
      .get("/admin/payment-commission")
      .then((res) => {
        console.log(res.data.data)
        setCommissionData(res.data.data);
      })
      .catch((err) => console.log(err));
    }
    
    const getData = () => {
        setType("")
        getCommissionData()
    }

    useEffect(() => {
        getCommissionData()
    },[])
  return (
    <>
        {open && (<CommissionModal
        open={open}
        setOpen={setOpen}
        type={type}
        getData={getData}
      />)}
      <div>
        {commissionData.length > 0 ? (
          <div className="mt-3 text-white px-3 py-3 div-dynamic ">
            <table>
              <thead>
                <tr>
                  <th>Feature</th>
                  <th>Commission-rate</th>
                </tr>
              </thead>
              <tbody>
                {commissionData.map((data, i) => (
                  <tr key={`row-${i}`} className="tr-dynamic">
                    <td>
                      {data.feature_type}
                    </td>
                    <td>{data.percentile}</td>
                    <td className="td">
                        {/* {setType(data.feature_type)} */}
                      <button
                        className="btn btn-outline-info btn-sm d-flex align-items-center"
                        onClick={() =>{setType(data.feature_type); setOpen(true) }}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h6 className="mt-3 text-center text-white"> Data not found </h6>
        )}
      </div>
    </>
  );
}

export default Commission;
