import React from "react";

const ListMeeting = ({
  influencer,
  fan,
  meetingTime,
  duration,
  fanDp,
  infDp,
}) => {
  return (
    <div
      className="d-flex"
      style={{
        background: "#3D3D3D",
        borderRadius: "10px",
        padding: "10px",
        margin: "10px",
      }}
    >
      <div>
        <img
          src={`${process.env.REACT_APP_API_IMAGE}${fanDp}`}
          alt="fan"
          style={{ width: "50px",height:'50px', objectFit: "cover", borderRadius: "50%" }}
        />
        <img
          src={`${process.env.REACT_APP_API_IMAGE}${infDp}`}
          alt="influencer"
          style={{ width: "50px",height:'50px', objectFit: "cover", borderRadius: "50%",marginLeft:'-15px'}}
        />
      </div>
      <div className='mx-3' >
        <h6 className="text-white">
          {" "}
          {`${influencer} and ${fan} has a meeting at ${meetingTime}`}{" "}
        </h6>
        <p className="text-white my-1 ">Meeting Duration: {duration} minutes</p>
      </div>
    </div>
  );
};

export default ListMeeting;
