import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { Select } from "antd";
// import SearchBar from "../../global_component/SearchBar";
import ReactPaginate from "react-paginate";
import DatePicker from "../DatePicker/DatePicker";

const WalletView = ({ userId }) => {
  const [user, setUser] = useState([]);
  //   const [input, setInput] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [filters, setFilters] = useState(null);
  const [filters2, setFilters2] = useState(null);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState([new Date(), new Date()]);
  const [utcDates, setUtcDates] = useState([]);

  const getWalletList = useCallback(
    (e) => {
      let offset = 0;
      if (e.selected !== undefined) {
        offset = e.selected * 10;
      }
      axios
        .get(
          `/admin/transactions?${
            userId !== undefined ? `user_id=${userId}&` : ""
          }limit=${10}&offset=${offset}${
            filters === "" || filters === null
              ? ""
              : `&transaction_type=${filters}`
          }${
            filters2 === "" || filters2 === null
              ? ""
              : `&credit_debit=${filters2}`
          }${
            utcDates.length > 0
              ? `&from_date=${utcDates[0]}&to_date=${utcDates[1]}`
              : ""
          }`
        )
        .then((res) => {
          setUser(res.data.data);
          setCount(Math.ceil(res.data.count / 10));
        })
        .catch((err) => console.log(err));
    },
    [filters, filters2, utcDates, userId]
  );

  useEffect(() => {
    getWalletList("");

    return () => {
      setUser([]);
      setCount(0);
    };
  }, [getWalletList]);

  useEffect(() => {
    if (filters !== null || filters2 !== null) {
      getWalletList("");
      return () => {
        setUser([]);
        setCount(0);
      };
    }
  }, [filters, filters2, getWalletList]);

  // filter by transaction type
  const filterByTransaction = () => {
    axios
      .get("/admin/transactions-type")
      .then((res) => {
        setFilterList(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    filterByTransaction();
  }, []);

  const onChange = (e) => {
    if(e !== null && e.length  > 0){
        setValue(e);
        const startDate = moment.utc(e[0]).valueOf();
        const endDate = moment.utc(e[1]).valueOf();
        setUtcDates([startDate, endDate]);
    }else{
        setUtcDates([]);
        setValue([]);
    }
    getWalletList('');
  };

  return (
    <>
      <div className="d-flex">
        {/* <SearchBar
            input={input}
            setInput={setInput}
            placeholder = {"Search Wallet"}
            func={() => getWalletList('')}
            setUser={setUser}
        />  */}
        <div className="d-flex justify-content-end align-items-start ml-auto mr-4 mt-3">
          <Select
            className="mr-4 text-capitalize"
            size="default"
            defaultValue=""
            onChange={(value) => {
              setFilters(value === "" ? "" : value);
            }}
            style={{ width: 175 }}
          >
            <Select.Option value="">Filter by Transaction type</Select.Option>
            {filterList.map((data, i) => (
              <Select.Option
                className="text-capitalize"
                key={"transactionType" + i}
                value={data.type}
              >
                {data.type}
              </Select.Option>
            ))}
          </Select>
          <Select
            size="default"
            defaultValue=""
            onChange={(value) => {
              setFilters2(value === "" ? null : value);
            }}
            style={{ width: 175 }}
          >
            <Select.Option value="">All</Select.Option>
            <Select.Option key={"creditDebit" + 1} value={"credit"}>
              Credit
            </Select.Option>
            <Select.Option key={"creditDebit"} value={"debit"}>
              Debit
            </Select.Option>
          </Select>
          <DatePicker onChange={onChange} value={value} />
        </div>
      </div>
      <div className="text-white px-3 py-3 mt-5 div-admin ">
        {user && user.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Amount</th>
                <th>Credit/Debit</th>
                <th>Date</th>
                <th>Transaction Type</th>
                <th>To User</th>
              </tr>
            </thead>
            <tbody>
              {user.map((data, i) => (
                <tr key={i} className="tr-admin">
                  <td>{data.id}</td>
                  <td>{data.amount}</td>
                  <td>{data.credit_debit}</td>
                  <td>{moment(data.created_at).format("MMM DD, YYYY")}</td>
                  <td>{data.transaction_type}</td>
                  <td>{data.to_user}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h6 className="text-center text-white"> No Data Found </h6>
        )}
      </div>
      <div className="float-right">
        {count > 0 && user.length > 8 ? (
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={getWalletList}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
    </>
  );
};

export default WalletView;
