import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import CalendarView from '../../../../global_component/Calendar/CalendarView';
import InfluencerMeetingLayout from './InfluencerMeeting/InfluencerMeetingLayout';

const InfluencerMeeting = () => {
  // const [date, setDate] = useState(new Date());
  const [data, setData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios.get(`/admin/meetings/${id}`).then(res => {
      setData(res.data.data)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex px-3 py-3">
      {/* <div >
        <h6 className='text-white mb-5 mt-3 ' > Specific date/ Data Range </h6>
        <CalendarView date={date} setDate={setDate} />
      </div> */}
      <div className='flex-fill' >
        <InfluencerMeetingLayout data={data} />
      </div>
    </div>
  );
}

export default InfluencerMeeting
