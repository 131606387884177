import React from "react";
import ListMeeting from "../../../../../global_component/Meeting/ListMeeting";
import moment from "moment";

const Cancelled = ({ cancelled = [] }) => {
  return (
    <div className="mt-1">
      {cancelled.length > 0 ? (
        cancelled.map((data, i) => {
          return (
            <ListMeeting
            key={i}
            fan={data.guest_name}
            influencer={data.influencer_name}
            infDp={data.influencer_dp}
            fanDp={data.guest_dp}
            duration={data.duration}
            meetingTime={moment(data.start_time).format("HH:MM DD/MM/YYYY")}
            />
          );
        })
      ) : (
        <h6 className="text-white text-center my-3"> No Data Found </h6>
      )}
    </div>
  );
};

export default Cancelled;
