import React from 'react'
import SideNav from './Navbar/SideNav'
import './Navbar/navbar.css'
import Header from './Header'
import { useLocation } from 'react-router-dom'

const Layout = (props) => {
	const location = useLocation()
	return (
		<>
			<SideNav />
			<div className='main-content' >
				<Header name={location ? location.pathname.split('/')[1] : ''} />
				{props.children}
			</div>
		</>
	)
}

export default Layout
