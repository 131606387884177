import React from 'react'
import moment from 'moment'
const Slot = ({ slots = [] }) => {
	return (
		<div className='mt-1 d-flex flex-warp '  >
			{slots.length > 0 ? slots.map((data, i) => {
				return <span className='px-4 py-3 mx-2 my-2' key={`slot-row-${i}`} style={{
					border: '1px solid #3D3D3D',
					borderRadius: '10px', color: 'white'
				}} >
					<h6 className="text-light text-center m-0">{moment(data.start_time).format('HH:MM')} - {moment(data.start_time).add(parseInt(data.duration), 'minutes').format('HH:MM')}</h6>
					<h6 className="text-light text-center mt-2 mb-0">{moment(data.start_time).format('DD/MM/YYYY')}</h6>
				</span>
			}) : <h6 className='text-white text-center my-3' > No Data Found </h6>}
		</div>
	)
}

export default Slot
