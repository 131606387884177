import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import jwtDecode from "jwt-decode";

export const StateContext = createContext();

const ContextApi = (props) => {
  const [token, setToken] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const LoginSet = () => {
    setToken(localStorage.getItem("token"));
    setSuccess(localStorage.getItem("success"));
  };

  const Logout = () => {
    localStorage.setItem("success", "");
    localStorage.setItem("token", "");
    localStorage.setItem("exp", "");
    localStorage.setItem("admin", "");
    history.push("/");
    setToken("");
    setSuccess(false);
  };

  useEffect(() => {
    LoginSet();
  }, []);

  axios.interceptors.request.use(
    (request) => {
      request.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.data.status_code === 403) {
        const originalRequest = response.config;
        return axios
          .get(`/admin/refresh-token?token=${localStorage.getItem("token")}`)
          .then((res) => {
            if (res.data.status_code === 200) {
              localStorage.setItem("token", res.data.data.access_token);
              localStorage.setItem(
                "exp",
                JSON.stringify(
                  jwtDecode(res.data.data.token.access_token).expires
                )
              );
              originalRequest.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
              return axios(originalRequest)
            } else {
              Logout();
              notification.error({ message: "Not Authorize" });
              setTimeout(()=>{
              window.location.reload()
              },500)
              return res;
            }
          });
      }
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return (
    <StateContext.Provider value={{ LoginSet, success, token, Logout }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default ContextApi;
