
import React from "react";
import './globalstyle.css'


const DynamicTable = ({ data = [], loading }) => {
  return (<>
    {data.length > 0 ? <div className="mt-3 text-white px-3 py-3 div-dynamic ">
      <table >
        <thead >
          <tr>
            <th>Sl.No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Country</th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, i) => (
            <tr className='tr-dynamic' key={i}>
              <td>{i + 1}</td>
              <td>{data.fullname}</td>
              <td>{data.email}</td>
              <td>{data.mobile}</td>
              <td>{data.country}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> : <h6 className='mt-3 text-center text-white' > No data found </h6>}
  </>);
};

export default DynamicTable;
