import React from "react";
import { Modal, Form, Space } from "antd";

const ModalPopUp = ({ open, cancel, func, title}) => {

  return (
    <Modal
      centered={true}
      title="Basic Modal"
      bodyStyle={{
        background: "#222222",
        borderRadius: "15px",
        padding: "20px 20px 10px 20px",
      }}
      maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
      footer={false}
      visible={open}
      onCancel={() => cancel(false)}
    >
      <h5 className="text-white" style={{marginLeft : 140 + "px"}}>{title}</h5>
      <Form style={{marginTop : 30 + "px"}} >
        <div className="d-flex justify-content-center mt-2 ">
          <Space>
            <button className="btn--danger btn--solid px-4 py-2 mx-4" onClick={() => func()}>
               Yes
            </button>
            <button className="btn--secondary btn--solid px-4 py-2 mx-4" onClick={() => {cancel(false)}}>
               No
            </button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalPopUp;
