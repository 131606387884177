
const menu = [
  // { path: '/', name: 'Home' },
  { path: '/users', name: 'Users' },
  // { path: '/fans', name: 'Fans' },
  // { path: '/reports', name: 'Reports' },
  { path: '/requests', name: 'Requests' },
  { path: '/invite-codes', name: 'Invite Codes' },
  { path: '/miscellaneous', name: 'Miscellaneous' },
  { path: '/early-access', name: 'Early Access' },
  { path: '/admins', name: 'Admins' },
  { path: '/wallet', name: 'Wallet' },
  { path: '/commission', name: 'Commission' },
];

export default menu;