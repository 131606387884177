import React, { useEffect, useState } from "react";
import SearchBar from "../../global_component/SearchBar";
import FanTable from "./component/FanTable";
import axios from "axios";
import ReactPaginate from "react-paginate";

const FanHome = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [input, setInput] = useState("");
  const [count, setCount] = useState(0);

  const getFanList = (e) => {
    const selected = e.selected;
    const offset = selected * 20;
    axios
      .get(`/admin/fans?limit=${20}&offset=${offset}`)
      .then((res) => {
        setTableData(res.data.data.result);
        setCount(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  };

  useEffect(() => {
    axios
      .get("/admin/fans?limit=20&offset=0")
      .then((res) => {
        setTableData(res.data.data.result);
        setCount(Math.ceil(res.data.count / 20));
        setLoading(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  }, []);

  const handleSearch = () => {
    setLoading(true);
    axios
      .get(`/admin/fans?search=${input}`)
      .then((res) => {
        setTableData(res.data.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <SearchBar
        input={input}
        setInput={setInput}
        func={handleSearch}
        setTableData={setTableData}
      />
      <FanTable
        tableData={tableData}
        setTableData={setTableData}
        loading={loading}
        setLoading={setLoading}
      />
      <div className="float-right">
        {count > 1 ? (
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={getFanList}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FanHome;
