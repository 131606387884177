import React, { useState } from "react";
import { useLocation } from "react-router";
import "../influencer.css";
import InfluencerDetailsTab from "./influencer_tab/InfluencerDetailsTab";
import InfluencerFollowers from "./influencer_tab/InfluencerFollowers";
import InfluencerFollowing from "./influencer_tab/InfluencerFollowing";
import InfluencerMeeting from "./influencer_tab/InfluencerMeeting";
// import InfluencerPost from "./influencer_tab/InfluencerPost";
import InfluencerReport from "./influencer_tab/InfluencerReport";
import InfluencerWallet from "./influencer_tab/InfluencerWallet";
import InfluencerInviteCodes from "./influencer_tab/InfluencerInviteCodes";

const InfluencerTab = ({ user, posts, getInfluencer }) => {
  const location = useLocation()
  const [active, setActive] = useState(location.state ? location.state.active : 1);
  
  return (
    <>
      <div className="d-flex flex-row  ">
        <span
          onClick={() => setActive(1)}
          className={`flex-fill ${
            active === 1 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Details </h6>
        </span>
        <span
          onClick={() => setActive(2)}
          className={`flex-fill  ${
            active === 2 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Invite Codes</h6>
        </span>
        <span
          onClick={() => setActive(3)}
          className={`flex-fill  ${
            active === 3 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Wallet</h6>
        </span>
        <span
          onClick={() => setActive(4)}
          className={`flex-fill  ${
            active === 4 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Followers</h6>
        </span>
        <span
          onClick={() => setActive(5)}
          className={`flex-fill  ${
            active === 5 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Following</h6>
        </span>
        <span
          onClick={() => setActive(6)}
          className={`flex-fill  ${
            active === 6 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Meeting</h6>
        </span>
        <span
          onClick={() => setActive(7)}
          className={`flex-fill  ${
            active === 7 ? "influencertab-active" : "influencertab"
          }`}
        >
          <h6 className="text-center">Reports</h6>
        </span>
      </div>

      <div>
        {active === 1 ? (
          <InfluencerDetailsTab user={user} slink={user.social_media_links} getInfluencer={() => getInfluencer()} />
        ) : null}
        {active === 2 ? <InfluencerInviteCodes user={user} /> : null}
        {active === 3 ? <InfluencerWallet wallet={user.wallet} id={user.id} /> : null}
        {active === 4 ? <InfluencerFollowers id={user.id} /> : null}
        {active === 5 ? <InfluencerFollowing id={user.id} /> : null}
        {active === 6 ? <InfluencerMeeting /> : null}
        {active === 7 ? <InfluencerReport reports={user.reports}  /> : null}
      </div>
    </>
  );
};

export default InfluencerTab;
