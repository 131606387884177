import React  from "react";
import { Modal, Input, Space, Form } from "antd";

const EditTitleModal = ({ open,setOpen,editTitle,value,setValue}) => {
  
  return (
    <div>
      <Modal
        centered={true}
        title="Basic Modal"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <h5 className="text-white"> Title Name </h5>
        <Form>
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            placeholder="Enter Title Name"
            value={value.title}
            onChange={(e) => {
              setValue({...value,title:e.target.value});
            }}
          />
          <div className="d-flex justify-content-end mt-2 ">
            <Space size="large">
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  editTitle(value.id,value.title)
                }}
              >
                Save
              </h6>
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                  setValue({title:'',id:''});
                }}
              >
                Cancel
              </h6>
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default EditTitleModal;
