import React, { useState } from "react";
import { Modal, Input, Space, Form, notification, Radio } from "antd";
import axios from "axios";

const CreateAdmin = ({ open, setOpen, getAdmin }) => {
  const [data, setData] = useState({
    fullname: "",
    password: "",
    email: "",
    is_superuser: false,
  });
  const createAdmin = () => {
    if (
      data.fullname === "" ||
      data.password === "" ||
      data.email === "" ||
      data.is_superuser === ""
    )
      notification.error({ message: "All Fields Required" });
    axios
      .post("/admin/register", data)
      .then((res) => {
        notification.success({ message: "Admin Added Successfully" });
        setData({ fullname: "", password: "", email: "" });
        getAdmin();
        setOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Modal
        centered={true}
        title="Basic Modal"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <h5 className="text-white"> Create Admin </h5>
        <Form>
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            placeholder="Enter Name"
            value={data.fullname}
            onChange={(e) => {
              setData({ ...data, fullname: e.target.value });
            }}
          />
          <Input
            required={true}
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            placeholder="Enter Email"
            value={data.email}
            onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }}
          />{" "}
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            placeholder="Enter Password"
            value={data.password}
            type="password"
            onChange={(e) => {
              setData({ ...data, password: e.target.value });
            }}
          />
          <Radio.Group
            onChange={(e) => setData({ ...data, is_superuser: e.target.value })}
            style={{ width: "100%" }}
            defaultValue={false}
          >
            <Space>
              <label className="my-2 px-2" style={{ color: "#E2E2E2" }}>
                {" "}
                Make Superuser{" "}
              </label>
              <Radio style={{ color: "#E2E2E2" }} value={true}>
                Yes
              </Radio>
              <Radio style={{ color: "#E2E2E2" }} value={false}>
                No
              </Radio>
            </Space>
          </Radio.Group>
          <div className="d-flex justify-content-end mt-2 ">
            <Space>
              {" "}
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  createAdmin();
                }}
              >
                Create
              </h6>{" "}
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                  setData({ fullname: "", password: "", email: "" });
                }}
              >
                Cancel
              </h6>{" "}
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateAdmin;
