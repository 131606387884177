import React from "react";
import moment from "moment";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "./datepicker.css";

function DatePicker({ value, onChange }) {
  return (
    <div>
      <DateRangePicker
        onChange={onChange}
        value={value}
      />
    </div>
  );
}

export default DatePicker;
