import React, { useState } from "react";
import '../fan.css'
import FanDetailsTab from "./fan_tabs/FanDetailsTab";
import FanFollowingTab from "./fan_tabs/FanFollowingTab";
import FanMeetingTab from "./fan_tabs/FanMeetingTab";

const FanTab = ({ fan, getFan }) => {
  const [active, setActive] = useState(1);

  return (<>
    <div className="d-flex flex-row  ">
      <span onClick={() => setActive(1)} className={`flex-fill ${active === 1 ? 'fantab-active' : 'fantab'}`} >
        <h6 className='text-center'>Details </h6>
      </span>
      <span onClick={() => setActive(2)} className={`flex-fill  ${active === 2 ? 'fantab-active' : 'fantab'}`} >
        <h6 className='text-center' >Following </h6>
      </span>
      <span onClick={() => setActive(3)} className={`flex-fill  ${active === 3 ? 'fantab-active' : 'fantab'}`} >
        <h6 className='text-center' >Meeting Details</h6>
      </span>
    </div>
    <div>
      {active === 1 ? <FanDetailsTab fan={fan} getFan={getFan} /> : null}
      {active === 2 ? <FanFollowingTab fan={fan} /> : null}
      {active === 3 ? <FanMeetingTab fan={fan} /> : null}
    </div>
  </>);
};

export default FanTab;
