import React, { useContext, useState } from "react";
import { FiBell, FiChevronDown } from "react-icons/fi";
import { Dropdown, Menu } from "antd";
import NoficationPanel from "./NoficationPanel";
import { StateContext } from "../statemangement/ContextApi";
import ChangePassword from '../modules/admin/ChangePassword';


const Header = ({ name = "User List", user = "Jerome Bell" }) => {
  const { Logout } = useContext(StateContext);
  const [open, setOpen] = useState(false);
  const [openModal,setOpenModal] = useState(false)
  const menu = (
    <Menu style={{ margin: "0", padding: "0", float: "right",background: "#222222",width:'150px'}}>
      <Menu.Item
        style={{background: "#222222", color: "white",paddingRight:'20px'}}
        onClick={() => {
          Logout();
        }}
      >
        Logout
      </Menu.Item>
      <Menu.Item
        style={{ background: "#222222", color: "white",paddingRight:'20px'}}
        onClick={() => {
          setOpenModal(true)
        }}
      >
       Change Password
      </Menu.Item>
    </Menu>
  );
  return (
    <div>
      <h4
        className="d-inline-block text-white mt-1 pt-1 "
        style={{ textTransform: "capitalize" }}
      >
        {name.length === 0 ? 'Users': name}
      </h4>
      <span className="float-right d-flex align-items-center">
        <span
          className="text-white pr-5"
          style={{ fontSize: "20px", marginBottom: "5px", cursor: "pointer" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <FiBell />
        </span>
        <Dropdown overlay={menu} trigger={["click"]} >
          <h5
            className="d-inline-block text-white  mb-1"
            style={{ cursor: "pointer" }}
          >
            {localStorage.getItem("admin")
              ? JSON.parse(localStorage.getItem("admin")).fullname
              : ""}
            <FiChevronDown />
          </h5>
        </Dropdown>
      </span>
      <NoficationPanel open={open} setOpen={setOpen} />
      <ChangePassword open={openModal} setOpen={setOpenModal}  />
    </div>
  );
};

export default Header;
