import axios from "axios";
import React, { useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import moment from "moment";

const EarlyAccess = () => {
  const [email, setEmail] = useState([]);
  useEffect(() => {
    axios.get("/early-access").then((res) => {
      setEmail(res.data.data);
    });
  }, []);

  const data = email.length > 0 ? [...email] : [] ;
  const fileName = `FanClub-Early-Access_${moment().format('DD-MMM-YYYY_hh-mm-ss-SSS')}`;
  const exportType = "csv";

 const  ExportToExcel = () => {  
    exportFromJSON({ data, fileName, exportType })  
  }  

  return (<> 
  <div className='d-flex justify-content-end my-2 ' >
  {email.length > 0 ?   <h6  style={{cursor:'pointer',color:'rgb(244, 233, 0)'}} onClick={()=> ExportToExcel()} >
         Export Data
     </h6> : null}
  </div>
   
    <div className="text-white px-3 py-3 div-admin ">
      {email.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {email.map((data, i) => (
              <tr key={i} className="tr-admin">
                <td>{data.fullname}</td>
                <td>{data.email}</td>
                <td>{data.mobile}</td>
                <td>{moment(data.created_at).format('MMM DD, YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h6 className="text-center text-white"> No Data Found </h6>
      )}
    </div>
 </> );
};

export default EarlyAccess;
