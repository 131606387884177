import React from "react";
import "./globalstyle.css";
const DetailLayout = (props) => {
  return (
    <div className="detail-layout mt-4 " style={{position:'relative'}} >
      <div className="py-2  type-box">
        <h5 className="my-1 pl-4 fw-bolder" style={{ color: "#7D7D7D",fontSize:'18px'}}>
          User type: <span className="fw-bolder text-white">{props.type} </span>
        </h5>
      </div>
      <div className="px-3 py-3">{props.children}</div>
    </div>
  );
};

export default DetailLayout;
