import axios from "axios";
import React, { useEffect, useState } from "react";
import DynamicTable from "../../../../global_component/DynamicTable";
import ReactPaginate from "react-paginate";

const InfluencerFollowing = ({ id }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count,setCount] = useState(0)

  const getFollowing = (e) => {
    const selected = e.selected
    const offset = selected * 20;
    axios
    .get(`/admin/following-list/${id}?limit=${20}&offset=${offset}`)
    .then((res) => {
      setData(res.data.data);
      setLoading(true);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  };
  useEffect(() => {
    setLoading(true);
    axios
    .get(`/admin/following-list/${id}?limit=${20}&offset=${0}`)
    .then((res) => {
      setData(res.data.data);
      setCount(Math.ceil(res.data.count /20))
      setLoading(true);
    })
    .catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }, []);
  return (
    <div>
      <DynamicTable data={data} loading={loading} />
      <div className="float-right">
        {count > 1 ? (
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={getFollowing}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default InfluencerFollowing;
