import React from 'react';
import { Input } from 'antd';

const NumericInput = (props) => {
  const onChange = e => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      props.onChange(value);
    }
  };

  const onBlur = () => {
    const { value, onBlur, onChange } = props;
    let valueTemp = value;
    if (String(value).charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(String(valueTemp).replace(/0*(\d+)/, '$1'));
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <>
      <label style={{color: '#FFF'}}>{props.placeholder}</label>
      <Input
        {...props}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={props.placeholder}
        maxLength={25}
      />
    </>
  )
}

export default NumericInput;