import React, { useState, useEffect } from "react";
import { Modal, Form, Space } from "antd";
import NumericInput from "../../NumericInput";

const AddNew = ({ open, setOpen, handleGenerate }) => {
  const [number, setNumber] = useState(1);

  useEffect(() => {
    setNumber(1);
  }, [open]);

  return (
    <div>
      <Modal
        centered={true}
        title="Generate Invite Codes"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <Form>
          {/* <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            type="number"
            required={true}
            placeholder="Enter no of codes to generate"
            value={number}
            min="1"
            max="100"
            step="1"
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          /> */}
          <NumericInput
            placeholder="Enter No. of Codes"
            onChange={(value) => setNumber(value)}
            value={number}
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
          />
          <div className="d-flex justify-content-end mt-4 ">
            <Space size="large">
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  handleGenerate(number)
                }}
              >
                Generate Codes
              </h6>
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </h6>
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNew;
