import { Spin } from "antd";
import axios from "axios";
import React,{useState} from "react";
import { useHistory } from "react-router-dom";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import "./influencer.css";
import moment from "moment";


const InfluencerTable = ({ tableData, loading,setLoading,setTableData }) => {
  const history = useHistory();
  const [sortName,setSortName] = useState({sort:"fullname",order:false})

  const sortTableData = () => {
    setLoading(true)
    axios.get(`/admin/users?limit=10&offset=0&sortBy=${sortName.sort}&sortOrder=${sortName.order ? 'asc' : 'desc'}`).then(res => {
      setLoading(false) 
         setTableData(res.data.data.result)
    }).catch(err => {
        console.log(err)
        setLoading(false)
    })
  }

  return (
    <div className="mt-5 text-white px-3 py-3 div-fan ">
      {loading ? (
        <Spin size="large" />
      ) : tableData.length === 0 ? (
        <h5 className="text-white text-center" > No Data Found </h5>
      ) : tableData.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Sl.No</th>
              <th onClick={()=>{
                setSortName({sort:"fullname",order:!sortName.order})
                sortTableData()
              }} >Name {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>
              <th onClick={()=>{
                setSortName({sort:"username",order:!sortName.order})
                sortTableData()
              }}>Username {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Type</th>
              <th onClick={()=>{
                setSortName({sort:"is_hidden",order:!sortName.order})
                sortTableData()
              }}>Hidden  {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>
              <th>Report Count</th>
              <th onClick={()=>{
                setSortName({sort:"verified",order:!sortName.order})
                sortTableData()
              }}>Verified  {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>
              <th onClick={()=>{
                setSortName({sort:"active",order:!sortName.order})
                sortTableData()
              }}>Active  {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>
              <th onClick={()=>{
                setSortName({sort:"created_at",order:!sortName.order})
                sortTableData()
              }}>Created At {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill /> }</th>

            </tr>
          </thead>
          <tbody>
            {tableData.map((data, i) => (
              <tr 
              key={data.id}
                className="tr-fan"
                onClick={() => history.push(`/users/${data.id}`)}
              >  
                <td>{i + 1}</td>
                <td>{data.fullname}</td> 
                <td>{data.username}</td>
                <td>{data.mobile}</td>
                <td>{data.email}</td>
                <td>{data.account_type}</td>
                <td>{data.is_hidden ? "Yes" : "No"}</td>
                <td>{data.report_counts}</td>
                <td>{data.verified ? "Yes" : "No"}</td>
                <td>{data.active ? "Yes" : "No"}</td>
                <td>{moment(data.created_at).format('MM/DD/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InfluencerTable;
