import React from "react";
import { useHistory } from 'react-router';
import axios from "axios";
import { notification } from "antd";
import TitleDetail from "../../../../global_component/TitleDetail";

const FanDetailsTab = ({ fan, getFan }) => {
  const history = useHistory();

  const updateStatus = (block = true) => {
    axios
      .put(`/admin/${!block ? 'activate' : 'deactivate'}/`, { user_id: fan.id })
      .then((res) => {
        notification.success({ message: `Account ${!block ? 'activated' : 'blocked'} successfully!` });
        getFan();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteUser = () => {
    if (window.confirm('Are you sure you want to delete this fan?')) {
      axios
      .delete(`/admin/user/${fan.id}`)
      .then(() => {
        notification.success({ message: `User deleted successfully!` });
        history.push("/fans");
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return fan ? (<>
    <section className='d-flex mt-4' >
      <div className='flex-fill px-3 '   >
        <TitleDetail title="Name" detail={fan.fullname ? fan.fullname : 'N/A'} />
        <TitleDetail title="Email" detail={fan.email ? fan.email : 'N/A'} />
        <TitleDetail title="Country" detail={fan.country ? fan.country : 'N/A'} />
      </div>
      <div className='flex-fill px-3 ' >
        <TitleDetail title="Registered Number" detail={fan.mobile ? fan.mobile : 'N/A'} />
        <TitleDetail title="Date of Birth" detail={fan.dob ? fan.dob : 'N/A'} />
      </div>
      <div className='flex-fill px-3 text-center ' >
        <img
          src={`${process.env.REACT_APP_API_IMAGE}${fan.profilePic}`}
          alt="profile"
          className="rounded-circle mb-4 "
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    </section>
    <div className="d-flex justify-content-end pt-4 pb-2 px-4">
      <button className="btn--secondary btn--solid px-4 py-2" onClick={() => updateStatus(fan.active)}>
        {fan.active ? 'Block' : 'Unblock'}
      </button>

      <button className="btn--danger btn--solid px-4 py-2 ml-4" onClick={deleteUser}>
        Delete User
      </button>
    </div>
  </>) : null;
};

export default FanDetailsTab;
