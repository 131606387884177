import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { notification } from "antd";

import PreLoader from "../../../global_component/PreLoader";

const ReportRequest = () => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const history = useHistory()

  const getRequest = () => {
    setLoading(true);
    axios
      .get(`/admin/report-request`)
      .then((res) => {
        setLoading(false);
        setRequests(res.data.data);
      }).catch(err => {
        console.log(err);
        setLoading(false);
        notification.error({ message: "Something went wrong, Please try again!" });
      });
  };

  useEffect(() => {
    getRequest();
  }, []);

  return loading ? <PreLoader /> : (<>
    <div>
      {requests.length > 0 ? (
        <div className="mt-3 text-white px-3 py-3 div-dynamic ">
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Reported By</th>
                <th>Reason</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((data, i) => (
                <tr className="tr-dynamic">
                  <td>{data.reported_on}</td>
                  <td>{data.reported_by}</td>
                  <td>{data.description}</td>
                  <td className='td' onClick={() => {
                    history.push({
                      pathname: `/creators/${data.reported_on_user_id}`,
                      state: { active: 7 }
                    })
                  }} >View</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="mt-3 text-center text-white"> No data found </h6>
      )}
    </div>
  </>);
};

export default ReportRequest;
