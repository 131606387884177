import React from "react";
import {
  FaDiscord,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
  FaSoundcloud,
  FaTelegram,
  FaSpotify,
  FaTwitch,
  FaTwitter,
  FaYoutube

} from "react-icons/fa";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";

const SocialIcons = ({ platform, link }) => {
  switch (platform) {
    case "AppleMusic":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <BsMusicNoteBeamed />
        </span>
      );
    case "Discord":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaDiscord />
        </span>
      );
    case "Instagram":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaInstagram />
        </span>
      );
    case "Linkedin":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaLinkedin />
        </span>
      );
    case "Pinterest":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaPinterest />
        </span>
      );
    case "Soundcloud":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaSoundcloud />
        </span>
      );
    case "Telegram":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaTelegram />
        </span>
      );
    case "Spotify":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <FaSpotify />
        </span>
      );
    case "TikTok":
      return (
        <span
         className='s-icons' 
          onClick={() => {
            window.open(link, "_blank");
          }}
        >
          <SiTiktok />
        </span>
      );
      case "Twitch":
        return (
          <span
           className='s-icons' 
            onClick={() => {
              window.open(link, "_blank");
            }}
          >
            <FaTwitch />
          </span>
        );
        case "Twitter":
            return (
              <span
               className='s-icons' 
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                <FaTwitter />
              </span>
            );
        case "Youtube":
                return (
                  <span
                   className='s-icons' 
                    onClick={() => {
                      window.open(link, "_blank");
                    }}
                  >
                    <FaYoutube />
                  </span>
                );
    default:
      return null;
  }
};

export default SocialIcons;
