import React, { useState } from 'react'
import Cancelled from './Cancelled'
import Past from './Past'
import Slot from './Slot'
import Upcoming from './Upcoming'

const InfluencerMeetingLayout = ({ data }) => {
  const [active, setActive] = useState(1)
  return (<>
    <div className=' px-3 py-3'  >
      <div className="d-flex flex-row px-3">
        {['Slot', 'Upcoming', 'Past', 'Cancelled'].map((data, i) => {
          return <span onClick={() => setActive(i + 1)} className={`flex-fill ${active === i + 1 ? 'fantab-active' : 'fantab'}`} key={`meeting-tabs-${i}`}>
            <h6 className='text-center' > {data} </h6>
          </span>
        })}
      </div>
      <div style={{ height: '100%', overflowY: 'auto' }} >
        {active === 1 ? <Slot slots={data ? data.slots : []} /> : null}
        {active === 2 ? <Upcoming upcoming={data ? data.upcoming : []} /> : null}
        {active === 3 ? <Past past={data ? data.past : []} /> : null}
        {active === 4 ? <Cancelled cancelled={data ? data.cancelled : []} /> : null}
      </div>
    </div> </>
  )
}

export default InfluencerMeetingLayout
