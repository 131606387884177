import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { notification, Button } from 'antd'
import ReportModal from './ReportModal';
import EditReportModal from './EditReportModal';
import {
  FiTrash,
  FiEdit
} from "react-icons/fi";
import PreLoader from "../../../global_component/PreLoader";

const Report = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState({ reason: "", id: "" });

  const getReport = () => {
    setLoading(true);
    axios.get("/admin/report/").then((res) => {
      setData(res.data.data.result);
      setLoading(false);
    });
  };
  useEffect(() => {
    getReport();
  }, []);

  const deleteReport = (id) => {
    axios
      .delete(`/admin/report/${id}`)
      .then((res) => {
        getReport();
        notification.success({ message: 'Reason Deleted' })
      })
      .catch((err) => console.log(err));
  };

  const editReport = (id, reason) => {
    axios.put(`/admin/report/`, { ID: id, UpdatedReason: reason }).then(res => {
      setOpenEdit(false)
      getReport()
      notification.success({ message: 'Title Edit Successfully' })
    }).catch(err => {
      console.log(err)
    })
  };

  return (
    <div>
      <div className="text-white div-dynamic mt-2 px-3">
        <div className="d-flex align-items-center my-3">
          <h5 className='d-inline-block text-white' >
            Report Reasons
          </h5>
          <Button
            type="text"
						className="btn--secondary ml-auto"
            onClick={() => setOpen(true)}
          >
            Add Reason
          </Button>
        </div>
        {loading && <PreLoader />}
        {!loading && <table>
          <thead>
            <tr className="tr-dynamic">
              <th>Sl.No</th>
              <th>Reason</th>
              <th className="text-right">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 && data.map((data, i) => (
              <tr className="tr-dynamic">
                <td>{i + 1}</td>
                <td>{data.reason}</td>{" "}
                <td
                  className='td'
                >
                  <div className='d-flex align-items-center justify-content-end'>
                    <button
                      className='btn btn-outline-info btn-sm d-flex align-items-center'
                      onClick={() => {
                        setValue({ reason: data.reason, id: data.id });
                        setOpenEdit(true)
                      }}
                    >
                      <FiEdit className='mr-2' /> Edit
                    </button>
                    <button
                      className='btn btn-outline-danger btn-sm d-flex align-items-center ml-4'
                      onClick={() => {
                        deleteReport(data.id);
                      }}
                    >
                      <FiTrash className='mr-2' /> Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {(!data || data.length === 0) &&
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={3}>
                  No data found!
                </td>
              </tr>
            }
          </tbody>
        </table>}
      </div>
      <ReportModal setOpen={setOpen} open={open} getReport={getReport} />
      <EditReportModal editReport={editReport} open={openEdit} setOpen={setOpenEdit} value={value} setValue={setValue} />
    </div>)
}

export default Report
