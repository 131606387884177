import React, { useState } from "react";
import { Modal, Input, Space, Form, notification } from "antd";
import axios from "axios";

const ReportReasonModal = ({ open, setOpen, getReport}) => {
  const [data, setData] = useState('');
  const createTitle = () => {
    if (data === "") notification.error({ message: "Field Required" });
    axios
      .post("/admin/posts/report/", {
    Reason:data
      })
      .then((res) => {
        notification.success({ message: "Reason Added Successfully" });
        setData("");
        getReport();
        setOpen(false)
      })
      .catch((err) => {
        console.log(err);
        notification.error({ message: "Something Went Wrong" });
      });
  };
  return (
    <div>
      <Modal
        centered={true}
        title="Basic Modal"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <h5 className="text-white"> Reason Name </h5>
        <Form>
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            placeholder="Enter Reason Name"
            value={data}
            onChange={(e) => {
              setData(e.target.value);
            }}
          />
          <div className="d-flex justify-content-end mt-2 ">
            <Space size="large">
              {" "}
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  createTitle();
                }}
              >
                Add
              </h6>{" "}
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                  setData("");
                }}
              >
                Cancel
              </h6>{" "}
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ReportReasonModal;
