import React from "react";
import { NavLink } from 'react-router-dom'
import "./navbar.css";

import menu from "../../../menu";

const LinkNav = () => {
  return (
    <>
      {menu.map((item, i) => {
        return (
          <NavLink to={item.path} >
            <span className={`d-flex align-items-center justify-content-start my-3 my-3 selected `}>
              <h5 className="text-h5 m-0">{item.name}</h5>
            </span>
          </NavLink>
        )
      })}
    </>
  );
};

export default LinkNav;
