import React,{useState} from "react";
import Title from "./title/Title";
import './miscellaneous.css'
import Report from "./report/Report";
import ReportReason from "./report_reason/ReportReason";
// import ReportReason from "../report_reason/ReportReason";

const Miscellaneous = ({fan}) => {
    const [active,setActive] = useState(1)
  return (<>
  <div className='detail-layout px-3 py-3 mt-3 '  >
    <div className="d-flex flex-row  ">
     {['Titles','Report Reasons','Report Post Reason'].map((data,i)=>{
         return  <span onClick={()=>setActive(i+1)}  className={`flex-fill ${active === i+1 ? 'fantab-active': 'fantab'}`} >  
         <h6 className='text-center' > {data} </h6>
       </span>
     })}
    </div>
    <div>
      {active === 1 ?  <Title />: null} 
      {active === 2 ? <Report />: null}
      {active ===3 ? <ReportReason/>:null}
      </div>
 </div> </> );
};

export default Miscellaneous;
