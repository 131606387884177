
import React from 'react'

const TitleDetail = ({ title, detail }) => {
  return (
    <span className='d-block my-3' >
      <p style={{ color: '#969696', fontSize: '14px' }} >{title}</p>
      <h5 className='my-2 text-white pb-2' style={{ borderBottom: '1px solid #3D3D3D' }} >{detail}</h5>
    </span>
  )
}

export default TitleDetail
