import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { Space, notification, Button, Select, Typography } from 'antd'
import {
  FiTrash,
  FiEdit
} from "react-icons/fi";

import AddNew from "./AddNew";
import EditModal from "./Edit";
import PreLoader from "../../PreLoader";

const { Paragraph } = Typography;

const InviteCodes = ({ user, admin, request_id }) => {
  const [loading, setLoading] = useState(false);
  const [inviteCodes, setInviteCodes] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [value, setValue] = useState({ code: "", invite_id: "" });
  const [filters, setFilters] = useState({
    isUsed: null,
    userType: null
  });

  const getInviteCodes = () => {
    setLoading(true);
    let url = `/admin/invite-codes/`;
    if (user) {
      url = `${url}?user_id=${user.id}`;
    }
    axios
      .get(url)
      .then((res) => {
        setLoading(false);
        const sortedArray = _.orderBy(res.data.data, ['is_used', 'created_at'], ['asc', 'desc']);
        setInviteCodes(sortedArray);
      }).catch(err => {
        console.log(err);
        setLoading(false);
        notification.error({ message: "Something went wrong, Please try again!" });
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`/invite/delete-invite/${id}`)
      .then((res) => {
        getInviteCodes();
        notification.success({ message: 'Invite code deleted successfully!' })
      })
      .catch((err) => console.log(err));
  };

  const handleUpdate = (id, code) => {
    axios.patch(`/admin/invites`, { invite_id: id, code: code }).then(res => {
      if (!res.data.success) {
        notification.error({ message: res.data.data.detail });
        return false;
      }
      setOpenEdit(false)
      getInviteCodes()
      notification.success({ message: 'Invite code updated successfully' })
      console.log(res);
    }).catch(err => {
      console.log(err)
    })
  };

  const getCount = (used = false) => {
    let count = 0;
    for (let x of inviteCodes) {
      if (x.is_used === used) {
        count++;
      }
    }

    return count;
  }

  const generateInviteCodes = (number) => {
    setOpenAdd(false);
    let data = {
      user_id: user ? user.id : undefined,
      no_of_codes: number,
      invite_code_request_id: request_id ? request_id : 0
    };
    if (admin) {
      data.admin_id = admin.id;
    }
    axios.post(`/admin/${admin ? 'generate-user-invite' : 'generate-invite-code'}`, data).then(res => {
      getInviteCodes();
    })
  }

  const renderRows = () => {
    return inviteCodes.map((data, i) => {
      if (filters.isUsed !== null && filters.userType !== null && filters.isUsed !== data.is_used && data.used_by !== null && data.used_by.influencer !== filters.userType) return null;
      else if (filters.isUsed !== null && filters.isUsed !== data.is_used) return null;
      else if (filters.userType !== null && data.used_by === null) return null;
      else if (filters.userType !== null && data.used_by !== null && data.used_by.influencer !== filters.userType) return null;

      return (
        <tr className="tr-admin non-clickable" key={`invite-code-row-${i}`}>
          <td>
            <Paragraph className="text-light m-0" copyable={!data.is_used}>{data.code}</Paragraph>
          </td>
          {admin && <td>
            {data.user
              ? <Link
                className="text-primary"
                to={`/${data.user.influencer ? 'creators' : 'fans'}/${data.user.id}`}
              >
                {data.user.first_name} {data.user.last_name}
              </Link>
              : 'Admin'
            }
          </td>}
          <td>
            {data.used_by
              ? <Link
                className="text-primary"
                to={`/${data.used_by.influencer ? 'creators' : 'fans'}/${data.used_by.id}`}
              >
                {data.used_by.first_name} {data.used_by.last_name}
              </Link>
              : '--'
            }
          </td>
          <td>
            {data.used_by ? data.used_by.influencer ? 'Creator' : 'Fan' : "--"}
          </td>
          <td>
            <span className={`${data.is_used ? 'text-warning' : 'text-info'}`}>{data.is_used ? 'Applied' : 'Available'}</span>
          </td>
          <td>{moment(data.created_at).format("MMM DD, YYYY")}</td>
          <td
            className='td'
          >
            <div className='d-flex align-items-center justify-content-end'>
              <button
                className='btn btn-outline-info btn-sm d-flex align-items-center'
                disabled={data.is_used}
                onClick={() => {
                  setValue({ code: data.code, invite_id: data.id });
                  setOpenEdit(true);
                }}
              >
                <FiEdit className='mr-2' /> Edit
              </button>
              <button
                className='btn btn-outline-danger btn-sm d-flex align-items-center ml-4'
                disabled={data.is_used}
                onClick={() => {
                  handleDelete(data.id);
                }}
              >
                <FiTrash className='mr-2' /> Delete
              </button>
            </div>
          </td>
        </tr>
      );
    });
  }

  useEffect(() => {
    getInviteCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? <PreLoader /> : (<>
    <div>
      <div className='d-flex justify-content-end align-items-start mt-4'>
        <div className="d-flex justify-content-end align-items-center mr-auto text-light">
          <h5 className="text-muted m-0">Total: &nbsp;<span className="text-light">{inviteCodes.length}</span></h5>
          <span className="ml-4">
            <div className="text-muted d-flex justify-content-start align-items-center">Used:   &nbsp;<span className="text-light ml-auto">{getCount(true)}</span></div>
            <div className="text-muted d-flex justify-content-start align-items-center">Available:   &nbsp;<span className="text-light ml-auto">{getCount(false)}</span></div>
          </span>
        </div>
        <div className="d-flex justify-content-end align-items-start ml-auto mr-4">
          <Select className="mr-4" size="default" defaultValue="" onChange={(value) => setFilters({...filters, userType: value === "" ? null : value})} style={{ width: 175 }}>
            <option value="">Filter by User Type</option>
            <option value={true}>Creator</option>
            <option value={false}>Fan</option>
          </Select>
          <Select size="default" defaultValue="" onChange={(value) => setFilters({...filters, isUsed: value === "" ? null : value})} style={{ width: 175 }}>
            <option value="">Filter by Status</option>
            <option value={true}>Applied</option>
            <option value={false}>Available</option>
          </Select>
        </div>
        <Space>
          <Button
            type="text"
            className="btn--secondary"
            onClick={() => setOpenAdd(true)}
          >
            Generate Invite Code
          </Button>
        </Space>
      </div>
      {inviteCodes.length > 0 ? (
        <>
          <div className="mt-3 text-white px-3 py-3 div-dynamic ">
            <table>
              <thead>
                <tr>
                  <th>Code</th>
                  {admin && <th>User</th>}
                  <th>Used By</th>
                  <th>User Type</th>
                  <th>Status</th>
                  <th>Date Created</th>
                  <th style={{ textAlign: 'right' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {renderRows()}
              </tbody>
            </table>
          </div>
          <EditModal handleUpdate={handleUpdate} open={openEdit} setOpen={setOpenEdit} value={value} setValue={setValue} />
        </>
      ) : (
        <h6 className="mt-3 text-center text-white"> No data found </h6>
      )}
      <AddNew handleGenerate={(number) => generateInviteCodes(number)} open={openAdd} setOpen={setOpenAdd} />
    </div>
  </>);
};

export default InviteCodes;
