import React from 'react'

const InfluencerReport = ({reports=[]}) => {
    return (
        <div>
        {reports.length > 0 ? (
          <div className="mt-3 text-white px-3 py-3 div-dynamic ">
            <table>
              <thead>
                <tr>
                  {" "}
                  <th>Sl.No</th>
                  <th>Reported By</th>{" "}
                  <th>Reason</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((data, i) => (
                  <tr className="tr-dynamic">
                    {" "}
                    <td>{i + 1}</td>
                    <td>{data.reported_by}</td>{" "}
                    <td>{data.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h6 className="mt-3 text-center text-white"> No data found </h6>
        )}
      </div>
    )
}

export default InfluencerReport
