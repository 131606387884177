import React, { useState } from "react";
import { Modal, Form, Space, Input } from "antd";
import axios from "axios";

const CommissionModal = ({ open, setOpen, type, getData}) => {

    const [percentile, setPercentile] = useState()
    const sendData = (percentile) => {
        const data = {
            "feature_type":  type,
            "percentile": percentile
        }
        axios
        .patch(`/admin/payment-commission`,data)
        .then((res) => {
          setOpen(false)
          getData();
        })
        .catch((err) => console.log(err));
    }

  return (
    <Modal
      centered={true}
      title="Basic Modal"
      bodyStyle={{
        background: "#222222",
        borderRadius: "15px",
        padding: "20px 20px 10px 20px",
      }}
      maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
      footer={false}
      visible={open}
      onCancel={() => setOpen(false)}
    >
      <h5 className="text-white" style={{marginLeft : 140 + "px"}}> Enter Commission-Rate </h5>
      <Form style={{marginTop : 30 + "px"}} >
        <div className="mx-auto d-flex justify-content-center mb-3">
            <Input type="number" className="w-25" onChange={(e) => setPercentile(e.target.value)} placeholder="Enter percentile" />
        </div>
        <div className="d-flex justify-content-center mt-2 ">
          <Space>
            <button className="btn--secondary btn--solid px-4 py-2 mx-4" onClick={() => sendData(percentile)}>
               Proceed
            </button>
            <button className="btn--danger  btn--solid px-4 py-2 mx-4" onClick={() => {setOpen(false)}} >
               Cancel
            </button>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default CommissionModal;
