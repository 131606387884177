import { Spin } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import "../fan.css";


const FanTable = ({ tableData, loading, setLoading, setTableData }) => {
  const history = useHistory();
  const [sortName, setSortName] = useState({ sort: "fullname", order: false })

  const sortTableData = () => {
    setLoading(true)
    axios.get(`/admin/fans?sortBy=${sortName.sort}&sortOrder=${sortName.order ? 'asc' : 'desc'}`).then(res => {
      setLoading(false)
      setTableData(res.data.data.result)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  return (
    <div className="mt-5 text-white px-3 py-3 div-fan ">
      {loading ? (
        <Spin size="large" />
      ) : tableData.length < 1 ? (
        <h5 className="text-white text-center"  > No Data Found </h5>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Sl.No</th>
              <th onClick={async () => {
                setSortName({ sort: "fullname", order: !sortName.order })
                sortTableData()
              }} >Name {sortName.order ? <BsCaretUpFill /> : <BsCaretDownFill />}</th>
              <th>Mobile</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((data, i) => (
              <tr
                className="tr-fan"
                onClick={() => history.push(`/fans/${data.id}`)}
                key={`table-row-${i}`}
              >
                <td>{i + 1}</td>
                <td>{data.fullname}</td>
                <td>{data.mobile}</td>
                <td>{data.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FanTable;
