import ContextApi from './components/statemangement/ContextApi';
import Router from './Router';

function App() {
  return (
    <ContextApi>
      <Router />
    </ContextApi>
  );
}

export default App;
