import React from "react";
import { Modal, Input, Space, Form } from "antd";

const EditModal = ({ open, setOpen, handleUpdate, value, setValue }) => {

  return (
    <div>
      <Modal
        centered={true}
        title="Edit Invite Code"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <Form>
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            placeholder="Enter Invite Code"
            value={value.code}
            onChange={(e) => {
              setValue({ ...value, code: e.target.value });
            }}
          />
          <div className="d-flex justify-content-end mt-2 ">
            <Space size="large">
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  handleUpdate(value.invite_id, value.code)
                }}
              >
                Save
              </h6>
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                  setValue({ code: '', invite_id: '' });
                }}
              >
                Cancel
              </h6>
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default EditModal;
