import React, { useState } from "react";
import { Modal, Input, Space, Form, notification } from "antd";
import axios from "axios";

const ChangePassword = ({ open, setOpen}) => {
  const [data, setData] = useState({
    new_password: "",
    old_password: "",
  });
  const changePass = () => {
    if (
      data.new_password === "" ||
      data.old_password === "" 
    )
      notification.error({ message: "All Fields Required" });
    axios
      .post(`/admin/change-password/${JSON.parse(localStorage.getItem('admin')).id}`, data)
      .then((res) => {
         if(res.data.status_code === 400){
             return notification.error({message:"Old Password didn't match"})
         }
         notification.success({ message: "Password Changed" });
         setData({
             new_password: "",
             old_password: "",
           });
         setOpen(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Modal
        centered={true}
        title="Basic Modal"
        bodyStyle={{
          background: "#222222",
          borderRadius: "15px",
          padding: "20px 20px 10px 20px",
        }}
        maskStyle={{ background: "rgba(0, 0, 0, 0.7)" }}
        footer={false}
        visible={open}
        onCancel={() => setOpen(false)}
      >
        <h5 className="text-white"> Change Password </h5>
        <Form>
          <Input
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            required={true}
            type='password'
            placeholder="Enter Old Password"
            value={data.fullname}
            onChange={(e) => {
              setData({ ...data, old_password: e.target.value });
            }}
          />
          <Input
            required={true}
            type='password'
            style={{
              background: "#222222",
              border: "none",
              margin: "10px 0",
              borderBottom: "1px solid #3D3D3D",
              color: "white",
            }}
            placeholder="Enter New Password"
            value={data.email}
            onChange={(e) => {
              setData({ ...data,new_password: e.target.value });
            }}
          />
          <div className="d-flex justify-content-end mt-2 ">
            <Space>
              {" "}
              <h6
                style={{ color: "#F4E900", cursor: "pointer" }}
                onClick={() => {
                  changePass();
                }}
              >
                Save
              </h6>{" "}
              <h6
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                  setData({
                    new_password: "",
                    old_password: "",
                  });
                }}
              >
                Cancel
              </h6>{" "}
            </Space>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ChangePassword;
