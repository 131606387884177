import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import moment from "moment";

import PreLoader from "../../../global_component/PreLoader";

const VerifyInfluencer = () => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const history = useHistory()

  const getRequest = () => {
    setLoading(true);
    axios
      .get(`/admin/verify-users?limit=20&offset=0`)
      .then((res) => {
        setLoading(false);
        setRequests(res.data.data);
      }).catch(err => {
        console.log(err);
        setLoading(false);
        notification.error({ message: "Something went wrong, Please try again!" });
      });
  };

  useEffect(() => {
    getRequest();
  }, []);

  return loading ? <PreLoader /> : (
    <>
      {requests.length > 0 ? <div className="mt-3 text-white px-3 py-3 div-dynamic ">
        <table >
          <thead >
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Country</th>
              <th>Type</th>
              <th>Created At</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((data, i) => (
              <tr className='tr-dynamic' key={`requests-${i}`}>
                <td>{data.fullname}</td>
                <td>{data.email}</td>
                <td>{data.mobile}</td>
                <td>{data.country}</td>
                <td>{data.account_type}</td>
                <td>{moment(data.created_at).format('DD/MM/YYYY')}</td>
                <td className='td' onClick={() => history.push(`/requests/application/${data.id}`)} >
                <button className="btn-view">View</button> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> : <h6 className='mt-3 text-center text-white' > No data found </h6>}
    </>
  )
}

export default VerifyInfluencer
