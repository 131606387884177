import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { notification } from "antd";

import PreLoader from "../../../global_component/PreLoader";

const InviteCodeRequest = () => {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);

  const getRequest = () => {
    setLoading(true);
    axios
      .get(`/admin/invite-requests`)
      .then((res) => {
        setLoading(false);
        setRequests(res.data.data);
      }).catch(err => {
        console.log(err);
        setLoading(false);
        notification.error({ message: "Something went wrong, Please try again!" });
      });
  };

  const handleOnGenerateCodes = (data) => {
    axios
      .post(`/admin/generate-invite-code`, {
        user_id: data.user.id,
        invite_code_request_id: data.id,
        no_of_codes: data.no_of_request
      })
      .then((res) => {
        notification.success({ message: res.data.data.detail });
        getRequest();
      }).catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    getRequest();
  }, []);



  return loading ? <PreLoader /> : (<>
    <div>
      {requests.length > 0 ? (
        <div className="mt-3 text-white px-3 py-3 div-dynamic ">
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>User Type</th>
                <th>No. of Requests</th>
                <th>Requested On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {requests.map((data, i) => (
                <tr key={`row-${i}`} className="tr-dynamic">
                  <td>{data.user.first_name} {data.user.last_name}</td>
                  <td>{data.user.influencer ? 'Creator' : 'Fan'}</td>
                  <td>{data?.no_of_request}</td>
                  <td>{moment(data.created_at).format("MMM DD, YYYY")}</td>
                  <td className="td">
                    <button
                      className='btn btn-outline-info btn-sm d-flex align-items-center'
                      disabled={data.is_used}
                      onClick={() => {
                        handleOnGenerateCodes(data);
                      }}
                    >
                      Generate Codes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="mt-3 text-center text-white"> No data found </h6>
      )}
    </div>
  </>);
};

export default InviteCodeRequest;
